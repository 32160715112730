<script>
import AddItem from './components/AddItem.vue';

export default {
  name: 'App',
  components: {
    AddItem
  }
};
</script>
