<template>
    <div>
      <input v-model="itemName" placeholder="Enter item name" />
      <button @click="addItem">Add Item</button>
    </div>
  </template>
  
  <script>
  import { collection, addDoc } from 'firebase/firestore';
  import { db } from '../firebase';
  
  export default {
    data() {
      return {
        itemName: ''
      };
    },
    methods: {
      async addItem() {
        try {
          await addDoc(collection(db, 'items'), {
            name: this.itemName
          });
          this.itemName = '';
        } catch (e) {
          console.error('Error adding document: ', e);
        }
      }
    }
  };
  </script>